import { render, staticRenderFns } from "./magic_link.vue?vue&type=template&id=126a0ead&scoped=true&"
import script from "./magic_link.vue?vue&type=script&lang=js&"
export * from "./magic_link.vue?vue&type=script&lang=js&"
import style0 from "./magic_link.vue?vue&type=style&index=0&id=126a0ead&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "126a0ead",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MagicTopbar: require('/opt/atlassian/pipelines/agent/build/components/magic/magic-topbar.vue').default,MagicSidebar: require('/opt/atlassian/pipelines/agent/build/components/magic/magic-sidebar.vue').default,Footer: require('/opt/atlassian/pipelines/agent/build/components/Footer.vue').default,RightSidebar: require('/opt/atlassian/pipelines/agent/build/components/Right-sidebar.vue').default})
